<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{$t('common.notice')}}</p>
        </div>
        <div class="RightBox">
          <form novalidate="" id="searchForm" class="ng-untouched ng-pristine ng-valid">
            <input type="hidden" name="board_id" value="">
            <input type="hidden" name="s_page" id="s_page" value="1">
            <input type="hidden" name="s_pagesize" id="s_pagesize" value="10">
          </form>
          <div id="datalist" class="noticeList">
            <div class="tbody ng-star-inserted">
              <div class="nList ng-star-inserted" @click="open_item=index"
                   v-for="(item,index) in list " :key="index">
                <ul class="num1"> {{ item.no }}</ul>
                <ul class="name1">
                  <li>{{ item.title }}</li>
                  <li class="date">{{ item.create_time }}</li>
                </ul>
                <div class="content ng-star-inserted" v-if="open_item == index">
                  <div v-html="item.memo"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
const CryptoJS = require("crypto-js");
export default {
  data() {
    return {
      open_item: -1,
      list: []
    }
  },
  mounted() {
    this.GetNoticeList();
  },
  methods: {
    GetNoticeList() {
      this.$axios.post('/member/board/GetNoticeList', {}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.list = body.list;
              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
  }
}
</script>
